.ClusterList {
    .ClusterList_wrapper {
        // @todo maybe should be moved to PageWrapper component
        .ant-page-header-heading-extra {
            display: flex;
        }
    }
    .ant-page-header-heading-left {
        flex: 1;
        > .ant-page-header-heading-title {
            width: 100%;
        }
    }
    .ClusterList_table {
        margin: 20px;
        display: table;
        width: calc(100% - 40px);
    }

    .ClusterList_row:hover + .ClusterList_expanded-row {
        td {
            background-color: @table-row-hover-bg;
        }
    }
    .ClusterList_expanded-row {
        td {
            padding: 10px 20px !important;
            background-color: @white;
        }
        &:hover {
            td {
                background-color: @white;
            }
        }
    }
    .ClusterList_detail-cell {
        display: flex;
        flex-direction: row;
    }
    .ClusterList_detail-cell_tags {
        font-size: 0.8em;
        width: 100%;
        min-width: 200px;
    }

    // override ant design to remove tr background on hover
    // and change border color
    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
        background: none;
        border-color: @primary-color;
        border-left-color: @primary-color !important;
        border-right-color: @primary-color !important;
    }

    .ant-table-tbody > tr.ant-table-row:hover + .ClusterList_expanded-row > td {
        background: white;
    }

    .ResponsiveTable--as-list-view:not(.ResponsiveTable--responsive)
        .ResponsiveTable_row--as-list-view:hover
        + .ResponsiveTable_expanded-row
        > td {
        border-left-color: @primary-color !important;
        border-right-color: @primary-color !important;
        border-bottom-color: @primary-color !important;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";